import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'fully_stocked',
  formId: 'DK%20Fully%20Stocked',
  title: 'Fully stocked',
  subTitle: 'Livet er for kort til at løbe tør for  toiletpapir... og rosé!',
  icon: '/icons/consumables.svg',
  image: '/assets/images/sunrise/consumables_supermercat.png',
  theme: 'sunrise-consumables',
  sections: [
    {
      sectionId: 'hero',
      label: 'Fully stocked',
      title: 'Livet er for kort til at løbe tør for  toiletpapir... og rosé!',
      description:
        'Vi sørger for, at I ikke løber tør. Vi har alt det, I har brug for på kontoret, så I kan koncentrere jer om det, I er der for. Vi fylder op. Så skal I kun sørge for ikke at løbe tør for gode ideer.',
      image: '/assets/images/sunrise/consumables_supermercat.png',
      icon: '/icons/consumables.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Alt det, der får kontorlivet til at køre på skinner',
      description:
        'De fleste af os tager mange ting på kontoret for givet, men det er altså ikke nisserne, der fylder lagrene om natten. Med ‘fyld-op’ sørger vi for, at I har alt det, I skal bruge.',
      optionsItems: [
        {
          icon: '/assets/images/services/consumables/service_1.svg',
          title: 'Kontorartikler',
          description:
            'Post-it notes, papir, penne og  printerpatroner. Find selv på mere og det behøver ikke at starte med P. Vi har det hele.',
        },
        {
          icon: '/assets/images/services/consumables/service_2.svg',
          title: 'Hygiejne',
          description:
            'Mere end nogensinde taler vi om hygiejne, selvom det altid har været vigtigt for at holde os raske og sunde. Lad os hjælpe jer med at holde kontor og hænder pletfri!',
        },
        {
          icon: '/assets/images/services/consumables/service_3.svg',
          title: 'Kaffe, te og drikkevarer',
          description:
            'Vi kan levere alle drikkevarer, varme som kolde, så kontoret ikke tørster. Dehydrering koster bare for meget energi.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/consumables/journey_1.svg',
          title: 'Produkter og priser',
          description: 'Vi sender dig en komplet oversigt. Ikke noget med småt.',
        },
        {
          icon: '/assets/images/services/consumables/journey_2.svg',
          title: 'Lyninspektion af kontoret',
          description: 'Vi sender en af vore assistenter ud for at kortlægge jeres behov.',
        },
        {
          icon: '/assets/images/services/consumables/journey_3.svg',
          title: 'Sæt i gang',
          description: 'Nu er det slut med at løbe tør... FOR EVIGT! Hvor sejt er det lige?',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Tre gode grunde til at vælge ‘Fyld-op’ service fra Good Monday',
      description: '',
      image: '/assets/images/services/consumables/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-layers-outline',
          title: 'Løb aldrig tør',
          description: 'Vi sørger for, at du aldrig NOGENSINDE løber tør for fornødenheder.',
        },
        {
          icon: 'eva eva-collapse-outline',
          title: 'Du behøver kun at handle eet sted',
          description: 'Vi kvitterer med et fuldt overblik over jeres forbrug på kontoret. Slut med mange bække små.',
        },
        {
          icon: 'eva eva-pricetags-outline',
          title: 'Få de bedste priser',
          description:
            'Vi er den indkøbsafdeling du ikke behøver at have og vi forhandler de bedste priser på dine vegne.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at du med Good Mondays ‘Fyld-op’ service aldrig løber tør igen?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
